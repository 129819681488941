<script setup>
    import {
        CLOCKING_TOLERANCE_MINUTE_DISPLAY_NAMES,
        EMPLOYEE_ANOMALY_RESOLUTION_MODE_CHOICES,
        WORKING_TIME_TOLERANCE_MINUTE_DISPLAY_NAMES,
    } from '@/features/company-policy/clocking/constants';
    import {CLOCKING_DEVICE, CLOCKING_DEVICE_DISPLAY_NAMES} from '@jetCommon/constants/clocking-constants';

    import ClockingAnomalyConfigDialog from '@/features/company-policy/clocking/components/ClockingAnomalyConfigDialog.vue';
    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInfoLine from '@jetDS/components/JetInfoLine.vue';
    import JetInfoLineGroup from '@jetDS/components/JetInfoLineGroup.vue';
    import JetInput from '@jetDS/components/JetInput.vue';
    import JetRadioButton from '@jetDS/components/JetRadioButton.vue';
    import JetRadioGroup from '@jetDS/components/JetRadioGroup.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';
    import WorkingHoursAnomalyDialog from '@/features/company-policy/clocking/components/WorkingHoursAnomalyConfigDialog.vue';

    const props = defineProps({
        policy: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['updated-form']);

    const defaultPolicyValues = {
        clocking_device: CLOCKING_DEVICE.WEB_OR_MOBILE,
        detect_geolocation: false,
        geolocation_anomalies: false,
        allowed_max_distance_from_hq: 100,
        notify_employees: false,
        employee_anomaly_resolution_mode: EMPLOYEE_ANOMALY_RESOLUTION_MODE_CHOICES.NONE,
        allowed_lacking_working_minutes: 30,
        allowed_additional_working_minutes: 30,
        entrance_clocking_enabled: true,
        exit_clocking_enabled: true,
        intermediate_clockings_enabled: false,
        allowed_early_entrance_minutes: 30,
        allowed_late_entrance_minutes: 30,
        allowed_early_exit_minutes: 30,
        allowed_late_exit_minutes: 30,
        allowed_early_start_intermediate_clocking_minutes: null,
        allowed_late_start_intermediate_clocking_minutes: null,
        allowed_early_end_intermediate_clocking_minutes: null,
        allowed_late_end_intermediate_clocking_minutes: null,
    };

    const formRef = ref(null);
    const formData = ref({
        ...defaultPolicyValues,
        ...props.policy,
    });

    watch(
        formData,
        () => {
            emit('updated-form', formData.value);
        },
        {deep: true},
    );

    const formRules = {
        name: [{required: true, message: 'Inserire il nome della policy', trigger: 'change'}],
        export_type: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
        refundable: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
        additional_export_fields: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
    };

    function isFormValid() {
        return formRef.value.getElFormRef().validate();
    }

    defineExpose({
        isFormValid,
    });

    const clockingDeviceOptions = Object.keys(CLOCKING_DEVICE).map(key => ({
        display_name: CLOCKING_DEVICE_DISPLAY_NAMES[CLOCKING_DEVICE[key]],
        value: CLOCKING_DEVICE[key],
    }));

    const anomalyResolutionModeChoices = [
        {
            display_name: 'Sì, solo con richieste',
            value: EMPLOYEE_ANOMALY_RESOLUTION_MODE_CHOICES.ABSENCE_REQUEST,
            caption: 'I dipendenti potranno risolvere le anomalie inserendo una presenza/assenza',
        },
        {
            display_name: 'Sì, con richieste e modifiche',
            value: EMPLOYEE_ANOMALY_RESOLUTION_MODE_CHOICES.ABSENCE_REQUEST_OR_MANUAL,
            caption:
                'I dipendenti potranno risolvere le anomalie inserendo una presenza/assenza o modificando a mano le timbrature',
        },
        {
            display_name: 'No',
            value: EMPLOYEE_ANOMALY_RESOLUTION_MODE_CHOICES.NONE,
            caption: 'I dipendenti non potranno risolvere le anomalie in alcun modo',
        },
    ];

    const allowedMaxDistanceFromHqChoiches = [
        {display_name: '50 metri', value: 50},
        {display_name: '100 metri', value: 100},
        {display_name: '150 metri', value: 150},
        {display_name: '200 metri', value: 200},
        {display_name: '250 metri', value: 250},
        {display_name: '300 metri', value: 300},
        {display_name: '350 metri', value: 350},
        {display_name: '400 metri', value: 400},
        {display_name: '450 metri', value: 450},
        {display_name: '500 metri', value: 500},
    ];

    const infoLineWorkingHoursAnomalyEditMode = ref(false);
    const infoLineClockingConfigEditMode = ref(false);

    function onWorkingHoursAnomalyDataUpdate(data) {
        formData.value.allowed_lacking_working_minutes = data.allowed_lacking_working_minutes;
        formData.value.allowed_additional_working_minutes = data.allowed_additional_working_minutes;
    }

    function onClockingAnomalyConfigDataUpdate(data) {
        formData.value.entrance_clocking_enabled = data.entrance_clocking_enabled;
        formData.value.exit_clocking_enabled = data.exit_clocking_enabled;
        formData.value.intermediate_clockings_enabled = data.intermediate_clockings_enabled;
        formData.value.allowed_early_entrance_minutes = data.allowed_early_entrance_minutes;
        formData.value.allowed_late_entrance_minutes = data.allowed_late_entrance_minutes;
        formData.value.allowed_early_exit_minutes = data.allowed_early_exit_minutes;
        formData.value.allowed_late_exit_minutes = data.allowed_late_exit_minutes;
        formData.value.allowed_early_start_intermediate_clocking_minutes =
            data.allowed_early_start_intermediate_clocking_minutes;
        formData.value.allowed_late_start_intermediate_clocking_minutes =
            data.allowed_late_start_intermediate_clocking_minutes;
        formData.value.allowed_early_end_intermediate_clocking_minutes =
            data.allowed_early_end_intermediate_clocking_minutes;
        formData.value.allowed_late_end_intermediate_clocking_minutes =
            data.allowed_late_end_intermediate_clocking_minutes;
    }

    const workingHoursAnomalyConfigText = computed(() => {
        const {allowed_lacking_working_minutes, allowed_additional_working_minutes} = formData.value;
        const earlyMessage = allowed_lacking_working_minutes
            ? `${WORKING_TIME_TOLERANCE_MINUTE_DISPLAY_NAMES[allowed_lacking_working_minutes]} in meno`
            : 'Nessun controllo in difetto';
        const lateMessage = allowed_additional_working_minutes
            ? `${WORKING_TIME_TOLERANCE_MINUTE_DISPLAY_NAMES[allowed_additional_working_minutes]} in più`
            : "Nessun controllo sull'eccesso";
        return `${earlyMessage} - ${lateMessage}`;
    });

    const clockingAnomalyConfig = computed(() => {
        return {
            entrance_clocking_enabled: formData.value.entrance_clocking_enabled,
            exit_clocking_enabled: formData.value.exit_clocking_enabled,
            intermediate_clockings_enabled: formData.value.intermediate_clockings_enabled,
            allowed_early_entrance_minutes: formData.value.allowed_early_entrance_minutes,
            allowed_late_entrance_minutes: formData.value.allowed_late_entrance_minutes,
            allowed_early_exit_minutes: formData.value.allowed_early_exit_minutes,
            allowed_late_exit_minutes: formData.value.allowed_late_exit_minutes,
            allowed_early_start_intermediate_clocking_minutes:
                formData.value.allowed_early_start_intermediate_clocking_minutes,
            allowed_late_start_intermediate_clocking_minutes:
                formData.value.allowed_late_start_intermediate_clocking_minutes,
            allowed_early_end_intermediate_clocking_minutes:
                formData.value.allowed_early_end_intermediate_clocking_minutes,
            allowed_late_end_intermediate_clocking_minutes:
                formData.value.allowed_late_end_intermediate_clocking_minutes,
        };
    });

    const workingHoursAnomalyData = computed(() => {
        return {
            allowed_lacking_working_minutes: formData.value.allowed_lacking_working_minutes,
            allowed_additional_working_minutes: formData.value.allowed_additional_working_minutes,
            exit_clocking_enabled: formData.value.exit_clocking_enabled,
        };
    });

    function computeClockingText(earlyEntranceMinutes, lateEntranceMinutes, isEnabled) {
        const computeSingleClockingString = (minutes, isEarly) => {
            if (minutes === null) {
                return isEarly ? "Nessun controllo sull'anticipo" : 'Nessun controllo sul ritardo';
            }
            return `${isEarly ? 'Anticipo' : 'Ritardo'} ${CLOCKING_TOLERANCE_MINUTE_DISPLAY_NAMES[minutes]}`;
        };

        if (!isEnabled) {
            return 'Non richiesta';
        }

        return `${computeSingleClockingString(earlyEntranceMinutes, true)} - ${computeSingleClockingString(lateEntranceMinutes, false)}`;
    }

    function setInfoLineWorkingHoursAnomalyEditMode(editMode) {
        infoLineWorkingHoursAnomalyEditMode.value = editMode;
    }

    function setInfoLineClockingConfigEditMode(editMode) {
        infoLineClockingConfigEditMode.value = editMode;
    }

    // we need to check also the string value that comes from the form (before submit)
    const isSwitchOn = switchValue => ['true', true].includes(switchValue);
</script>

<template>
    <JetInfoLineGroup>
        <JetForm ref="formRef" :rules="formRules" :model="formData" label-position="top">
            <JetInfoLine label="Nome della policy" action-label="">
                <JetFormItem prop="name">
                    <JetInput v-model="formData.name" class="TextInput"></JetInput>
                </JetFormItem>
            </JetInfoLine>
            <!-- TODO add link to "leggi l'articolo" before release -->
            <JetInfoLine
                label="Dispositivo di timbratura"
                description="Per conoscere le tipologie di timbratura leggi l'articolo"
                action-label="">
                <JetFormItem prop="clocking_device">
                    <JetSelect v-model="formData.clocking_device" :options="clockingDeviceOptions"></JetSelect>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine label="Rilevazione della posizione" action-label="">
                <JetFormItem prop="detect_geolocation">
                    <JetRadioGroup v-model="formData.detect_geolocation">
                        <JetRadioButton label="true">Attivo</JetRadioButton>
                        <JetRadioButton label="false">Disattivo</JetRadioButton>
                    </JetRadioGroup>
                </JetFormItem>
            </JetInfoLine>
            <template v-if="isSwitchOn(formData.detect_geolocation)">
                <div class="FakeInfoline">
                    <JetCallout type="info" plain class="my-2">
                        I dati sulla posizione non saranno più consultabili una volta elaborato il ciclo paghe di
                        riferimento per motivi di privacy.
                    </JetCallout>
                </div>
                <JetInfoLine label="Anomalie sulla posizione" action-label="">
                    <JetFormItem prop="geolocation_anomalies">
                        <JetRadioGroup v-model="formData.geolocation_anomalies">
                            <JetRadioButton label="true">Si</JetRadioButton>
                            <JetRadioButton label="false">No</JetRadioButton>
                        </JetRadioGroup>
                    </JetFormItem>
                </JetInfoLine>
                <template v-if="isSwitchOn(formData.geolocation_anomalies)">
                    <JetInfoLine
                        label="Distanza massima dalla sede aziendale"
                        description="Oltre tale distanza le timbrature genereranno anomalie"
                        action-label="">
                        <JetFormItem prop="allowed_max_distance_from_hq">
                            <JetSelect
                                v-model="formData.allowed_max_distance_from_hq"
                                :options="allowedMaxDistanceFromHqChoiches" />
                        </JetFormItem>
                    </JetInfoLine>
                    <div class="FakeInfoline">
                        <JetCallout type="info" plain class="my-2">
                            Il limite sulla posizione non viene applicato ai lavoratori in smartworking o trasferta.
                        </JetCallout>
                    </div>
                </template>
            </template>
            <JetInfoLine
                :edit-mode="infoLineClockingConfigEditMode"
                label="Timbrature richieste e anomalie"
                description="Specifica in quali occasioni il dipendente deve timbrare e entro quali limiti di tempo."
                @update:edit-mode="setInfoLineClockingConfigEditMode($event)">
                <template #lineEdit>
                    <ClockingAnomalyConfigDialog
                        :clocking-config="clockingAnomalyConfig"
                        @update:clocking-anomaly-config-data="onClockingAnomalyConfigDataUpdate($event)"
                        @update:show-dialog="setInfoLineClockingConfigEditMode($event)" />
                </template>
                <div class="ClockingAnomalyInfoLineContainer">
                    <div>
                        <p class="base secondary medium">Timbratura d'ingresso</p>
                        <p class="base primary medium">
                            {{
                                computeClockingText(
                                    clockingAnomalyConfig.allowed_early_entrance_minutes,
                                    clockingAnomalyConfig.allowed_late_entrance_minutes,
                                    clockingAnomalyConfig.entrance_clocking_enabled,
                                )
                            }}
                        </p>
                    </div>
                    <div>
                        <p class="base secondary medium">Timbratura d'uscita</p>
                        <p class="base primary medium">
                            {{
                                computeClockingText(
                                    clockingAnomalyConfig.allowed_early_exit_minutes,
                                    clockingAnomalyConfig.allowed_late_exit_minutes,
                                    clockingAnomalyConfig.exit_clocking_enabled,
                                )
                            }}
                        </p>
                    </div>
                    <div>
                        <p class="base secondary medium">Timbratura d'inizio pausa</p>
                        <p class="base primary medium">
                            {{
                                computeClockingText(
                                    clockingAnomalyConfig.allowed_early_start_intermediate_clocking_minutes,
                                    clockingAnomalyConfig.allowed_late_start_intermediate_clocking_minutes,
                                    clockingAnomalyConfig.intermediate_clockings_enabled,
                                )
                            }}
                        </p>
                    </div>
                    <div>
                        <p class="base secondary medium">Timbratura di fine pausa</p>
                        <p class="base primary medium">
                            {{
                                computeClockingText(
                                    clockingAnomalyConfig.allowed_early_end_intermediate_clocking_minutes,
                                    clockingAnomalyConfig.allowed_late_end_intermediate_clocking_minutes,
                                    clockingAnomalyConfig.intermediate_clockings_enabled,
                                )
                            }}
                        </p>
                    </div>
                </div>
            </JetInfoLine>
            <JetInfoLine
                :edit-mode="infoLineWorkingHoursAnomalyEditMode"
                label="Anomalie sul monte ore"
                description="Specifica di quanto tempo il dipendente si può scostare dal monte ore giornaliero."
                @update:edit-mode="setInfoLineWorkingHoursAnomalyEditMode($event)">
                <template #lineEdit>
                    <WorkingHoursAnomalyDialog
                        :working-hours-anomaly-data="workingHoursAnomalyData"
                        @update:working-hours-anomaly-data="onWorkingHoursAnomalyDataUpdate($event)"
                        @update:show-dialog="setInfoLineWorkingHoursAnomalyEditMode($event)" />
                </template>
                <p>{{ clockingAnomalyConfig.exit_clocking_enabled ? workingHoursAnomalyConfigText : 'Non attive' }}</p>
            </JetInfoLine>
            <JetInfoLine
                label="Invia notifiche ai dipendenti"
                description="Invia notifiche automatiche ai lavoratori quando stanno per timbrare oltre il ritardo consentito."
                action-label="">
                <JetFormItem prop="notify_employees">
                    <JetRadioGroup v-model="formData.notify_employees">
                        <JetRadioButton label="true">Sì</JetRadioButton>
                        <JetRadioButton label="false">No</JetRadioButton>
                    </JetRadioGroup>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                label="Consenti ai dipendenti di risolvere le anomalie"
                description="Puoi consentire ai dipendenti di risolvere in autonomia le anomalie in modo da non doverlo fare tu a fine mese."
                action-label="">
                <JetFormItem prop="employee_anomaly_resolution_mode">
                    <JetSelect
                        v-model="formData.employee_anomaly_resolution_mode"
                        :options="anomalyResolutionModeChoices"
                        option-height-fluid>
                        <template #optionCaption="{option}">
                            {{ option.caption }}
                        </template>
                    </JetSelect>
                </JetFormItem>
            </JetInfoLine>
        </JetForm>
    </JetInfoLineGroup>
</template>

<style scoped lang="scss">
    .ClockingAnomalyInfoLineContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .TextInput {
        max-width: 320px;
    }

    .FakeInfoline {
        border-bottom: var(--jet-info-line-border-bottom-width) solid var(--jet-border-color-base);
    }
</style>
